<template>
  <div>
    <div class="index-container overflow-hidden">
      <div class="index-slider-container-bg">
        <div class="container text-white">
          <div
            id="quranProjectCarousel"
            class="carousel slide py-5 pb-5 py-lg-0 pb-lg-0"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row py-0">
                  <div class="col-10 mx-auto row">
                    <div
                      class="
                        col-12 col-lg-6
                        py-5
                        d-none d-sm-block d-md-none d-lg-block
                        text-center
                        my-auto
                      "
                    >
                      <img
                        data-animation="animated bounceInDown"
                        src="@/assets/images/slider/chatonfaith-project.png"
                        width="350"
                        height="314"
                        class="img-fluid h-100"
                        alt="Chat on faith"
                      />
                    </div>
                    <div
                      class="
                        col-12 col-md-8 col-lg-6
                        my-auto
                        ms-lg-0
                        mx-md-auto
                        mb-lg-auto
                      "
                    >
                      <h1
                        class="mb-2 text-chatonfaith fw-bold"
                        data-animation="animated bounceInUp"
                      >
                        مشروع حوار الإيمان
                      </h1>
                      <div
                        class="info lh-lg text-end edc-color-gray"
                        data-animation="animated zoomInLeft"
                      >
                        مشروع يهدف إلى نشر الإسلام من خلال دعوة غير المسلمين
                        بسبع لغات عالمية من خلال أكثر من 20 داعية يعملون على
                        مدار الساعة في الدعوة إلى الله.
                      </div>
                      <router-link
                        :to="{ path: '/chat-on-faith/' }"
                        data-animation="animated bounceInLeft"
                        class="
                          btn
                          slider-reade-more
                          edc-btn-chatonfaith
                          text-white
                          py-2
                          px-3
                          rounded-50
                          mt-2
                        "
                      >
                        <span>{{ $t("btn.for_more") }}</span>
                        <i class="fas fa-chevron-left me-5"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!--//carousel-item1-text-chatonfaith-->
              </div>
              <!--//carousel-item1-chatonfaith-->
              <div class="carousel-item">
                <div class="row py-0">
                  <div class="col-10 mx-auto row">
                    <div
                      class="
                        col-12 col-lg-6
                        py-5
                        d-none d-sm-block d-md-none d-lg-block
                        text-center
                        my-auto
                      "
                    >
                      <img
                        data-animation="animated bounceInUp"
                        src="@/assets/images/slider/sabile-project.png"
                        width="350"
                        height="314"
                        class="img-fluid h-100"
                        alt="sableei Academy"
                      />
                    </div>
                    <div
                      class="
                        col-12 col-md-8 col-lg-6
                        my-auto
                        ms-lg-0
                        mx-md-auto
                        mb-lg-auto
                      "
                    >
                      <h1
                        class="mb-2 text-sabile fw-bold"
                        data-animation="animated bounceInUp"
                      >
                        أكاديمية سبيلي
                      </h1>
                      <div
                        class="info lh-lg text-end text-sub-sabile"
                        data-animation="animated zoomInLeft"
                      >
                        منصة تعليمية إلكترونية تهدف إلى تعليم المسلمين الجدد
                        والمسلمين الناطقين بغير اللغة العربية والمسلمين الراغبين
                        في تعلم العلم الشرعي الصحيح.
                      </div>
                      <router-link
                        :to="{ path: '/sabeeli-academy/' }"
                        data-animation="animated bounceInLeft"
                        class="
                          slider-reade-more
                          btn
                          edc-btn-sabile
                          text-white
                          py-2
                          px-3
                          rounded-50
                          mt-2
                        "
                      >
                        <span>{{ $t("btn.for_more") }}</span>
                        <i class="fas fa-chevron-left me-5"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!--//carousel-item2-sabile-->
              </div>
              <!--//carousel-item2-sabile-->
              <div class="carousel-item">
                <div class="row py-0">
                  <div class="col-10 mx-auto row">
                    <div
                      class="
                        col-12 col-lg-6
                        py-5
                        d-none d-sm-block d-md-none d-lg-block
                        text-center
                        my-auto
                      "
                    >
                      <img
                        data-animation="animated bounceInDown"
                        width="350"
                        height="314"
                        src="@/assets/images/slider/quran-project.png"
                        class="img-fluid h-100"
                        alt="Quran radios"
                      />
                    </div>
                    <div
                      class="
                        col-12 col-md-8 col-lg-6
                        my-auto
                        ms-lg-0
                        mx-md-auto
                        mb-lg-auto
                      "
                    >
                      <h1
                        class="mb-2 text-quran fw-bold"
                        data-animation="animated bounceInUp"
                      >
                        مشروع القرآن الكريم
                      </h1>
                      <div
                        class="info lh-lg text-end text-sub-quran"
                        data-animation="animated zoomInLeft"
                      >
                        مشروع يقوم على نشر القرآن الكريم وعلومه وترجماته
                        وتفاسيره بأكثر من 50 لغة عالمية، بحيث يتمكن الناطقين
                        بغير اللغة العربية من فهم معاني القرآن الكريم.
                      </div>
                      <router-link
                        :to="{ path: '/quran-radios/' }"
                        data-animation="animated bounceInLeft"
                        class="
                          slider-reade-more
                          btn
                          edc-btn-quran
                          text-white
                          py-2
                          px-3
                          rounded-50
                          mt-2
                        "
                      >
                        <span>{{ $t("btn.for_more") }}</span>
                        <i class="fas fa-chevron-left me-5"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!--//carousel-item3-content-quran-->
              </div>
              <!--//carousel-item3-quran-->
              <div class="carousel-item">
                <div class="row py-0">
                  <div class="col-10 mx-auto row">
                    <div
                      class="
                        col-12 col-lg-6
                        py-5
                        d-none d-sm-block d-md-none d-lg-block
                        text-center
                        my-auto
                      "
                    >
                      <img
                        data-animation="animated bounceInDown"
                        src="@/assets/images/slider/waqf-project.png"
                        class="img-fluid h-100"
                        width="350"
                        height="314"
                        alt="El Waqaf"
                      />
                    </div>
                    <div
                      class="
                        col-12 col-md-8 col-lg-6
                        my-auto
                        ms-lg-0
                        mx-md-auto
                        mb-lg-auto
                      "
                    >
                      <h1
                        class="mb-2 text-waqf fw-bold"
                        data-animation="animated bounceInUp"
                      >
                        مشروع الوقف
                      </h1>
                      <div
                        class="info lh-lg text-end edc-color-gray"
                        data-animation="animated zoomInLeft"
                      >
                        مشروع ينفق من ريعه على أنشطة وخدمات لجنة الدعوة
                        الإلكترونية والتعريف بالإسلام لغير المسلمين ونشر علوم
                        وترجمات القرآن الكريم بأكثر من 13 لغة.
                      </div>
                      <router-link
                        :to="{ path: '/edc-waqf/' }"
                        data-animation="animated bounceInLeft"
                        class="
                          slider-reade-more
                          btn
                          edc-btn-waqf
                          text-white
                          py-2
                          px-3
                          rounded-50
                          mt-2
                        "
                      >
                        <span>{{ $t("btn.for_more") }}</span>
                        <i class="fas fa-chevron-left me-5"></i>
                      </router-link>
                    </div>
                  </div>
                </div>
                <!--//carousel-item4-content-->
              </div>
              <!--//carousel-item4-waqf-->
              <div class="carousel-item">
                <div class="row py-0">
                  <div class="col-10 mx-auto row">
                    <div
                      class="
                        col-12 col-lg-6
                        py-5
                        d-none d-sm-block d-md-none d-lg-block
                        text-center
                        my-auto
                      "
                    >
                      <img
                        data-animation="animated bounceInDown"
                        src="@/assets/images/slider/media-project.png"
                        class="img-fluid h-100"
                        width="350"
                        height="314"
                        alt="Media productions"
                      />
                    </div>
                    <div
                      class="
                        col-12 col-md-8 col-lg-6
                        my-auto
                        ms-lg-0
                        mx-md-auto
                        mb-lg-auto
                      "
                    >
                      <h1
                        class="mb-2 edc-color-yellow fw-bold"
                        data-animation="animated bounceInUp"
                      >
                        مشروع الإنتاج المرئي
                      </h1>
                      <div
                        class="info lh-lg text-end edc-color-gray"
                        data-animation="animated zoomInLeft"
                      >
                        مشروع يهدف إلى زيادة الإنتاج والمحتوى المرئي وتوظيف هذا
                        الإنتاج لخدمة الإسلام مع ترجمة جميع الفيديوهات إلى أكثر
                        من 10 لغات عالمية.
                      </div>
                      <!-- <router-link
                        :to="{ path: '/chat-on-faith/' }"
                        data-animation="animated bounceInLeft"
                        class="
                          slider-reade-more
                          btn
                          edc-btn-yellow
                          text-white
                          py-2
                          px-3
                          rounded-50
                          mt-2
                        "
                      >
                        <span>{{ $t("btn.for_more") }}</span>
                        <i class="fas fa-chevron-left me-5"></i>
                      </router-link> -->
                    </div>
                  </div>
                </div>
                <!--//carousel-item5-content-->
              </div>
              <!--//carousel-item5-media-->
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#quranProjectCarousel"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#quranProjectCarousel"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeSlider",
};
</script>

<style lang="scss" scoped>
</style>