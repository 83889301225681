<template>
  <div v-if="total > 0">
    <div class="testimonial-container overflow-hidden py-5">
      <div class="container">
        <div class="d-flex justify-content-between">
          <h1 class="edc-color-primary fw-bold fs-3 mb-3 edc-section-title">
            {{ $t(head_title) }}
          </h1>
          <div
            class="nav d-flex justify-content-between gap-2 align-items-center"
          >
            <a href="#" @click="prev($event)" aria-label="next" class="px-2 py-1 border-0">
              <i class="fas fa-chevron-right" ></i>
            </a>
            <a href="#" @click="next($event)" aria-label="prev" class="px-2 py-1 border-0">
              <i class="fas fa-chevron-left" ></i>
            </a>
          </div>
        </div>
        <loading-page :isShow="isShowLoading"></loading-page>
        <div class="row">
          <div
            class="col-12 col-lg-12 mb-sm-2"
            data-aos="zoom-in"
            data-aos-easing="ease-in-back"
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <div class="testimonial-user mb-4 d-flex align-items-center">
              <div class="avatar mx-2">
                <img
                  v-if="testimonial.image.thumbnails"
                  lazysizes
                  :src="testimonial.image.thumbnails"
                  :data-src="testimonial.image.thumbnails"
                  class="rounded-circle d-block border border-3"
                  :alt="testimonial.title"
                  :title="testimonial.title"
                  width="80"
                  height="80"
                  data-aos="zoom-in"
                />
                <img
                  v-if="!testimonial.image.thumbnails"
                  lazysizes
                  src="@/assets/images/avatar.png"
                  class="rounded-circle d-block border border-3"
                  :alt="testimonial.title"
                  :title="testimonial.title"
                  width="80"
                  height="80"
                  data-aos="zoom-in"
                />
              </div>
              <div class="name-links flex-fill edc-color-red-new">
                <h1
                  class="h6 fw-bold"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  {{ testimonial.title }}
                </h1>
                <div data-aos="fade-right" data-aos-duration="1000">
                  <a
                    v-for="(social, index) in testimonial.socials"
                    :key="index"
                    :href="social.link"
                    aria-label="follow him"
                    target="_blank"
                    class="fs-2 edc-color-red-new mx-1"
                  >
                    <i class="fab" :class="social.icon" aria-hidden="true"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="testimonial-comment p-3 rounded-30 lh-lg text-muded">
              {{ testimonial.content }}
            </div>
          </div>
          <div class="alert alert-danger" v-if="isEmptyData">
            {{ $t("message.not_testimonial_found") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "./Loading.vue";
import LoadingPage from "./LoadingPage.vue";
import vueLazysizes from "vue-lazysizes";
export default {
  components: { Loading, LoadingPage },
  name: "testimonial",
  props: {
    isResetOnLoad: { type: Boolean, default: true },
    limit: { type: Number, default: 1 },
    section: { type: String, default: "home" },
    head_title: { type: String, default: "" },
  },
  data() {
    return {
      offset: 1,
    };
  },
  computed: {
    ...mapGetters("testimonial", [
      "testimonials",
      "isEmptyData",
      "total",
      "isShowLoading",
    ]),
  },
  methods: {
    ...mapActions("testimonial", ["getTestimonials", "resetTestimonials"]),
    get_api_testimonials() {
      this.offset++;
      this.getTestimonials({
        limit: this.limit,
        offset: this.offset,
        section: this.section,
      });
    },
    next(event) {
      event.preventDefault();
      if (this.offset >= 0 && this.testimonials.length > 0) {
        this.offset++;
        this.resetTestimonials([]);
        this.getTestimonials({
          limit: this.limit,
          offset: this.offset,
          section: this.section,
        });
      }
    },
    prev(event) {
      event.preventDefault();
      if (this.offset > 1) {
        this.offset--;
        this.resetTestimonials([]);
        this.getTestimonials({
          limit: this.limit,
          offset: this.offset,
          section: this.section,
        });
      }
    },
  },
  created() {
    if (this.isResetOnLoad === true) {
      this.resetTestimonials([]);
    }
    this.getTestimonials({
      limit: this.limit,
      offset: this.offset,
      section: this.section,
    });
  },
  directives: {
    lazysizes: vueLazysizes
  },
};
</script>

<style lang="scss" scoped>
</style>