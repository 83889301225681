<template>
  <div>
    <home-slider></home-slider>

    <div class="page-content py-5">
      <div class="container">
        <projects-list
          head_title="last_project"
          :limit="3"
          :isResetOnLoad="true"
        ></projects-list>

        <testimonial :head_title="`title.testimonial`"></testimonial>
      </div>
    </div>
  </div>
</template>
<script>
import HomeSlider from "../components/HomeSlider.vue";
import ProjectsList from "../components/ProjectsList.vue";
import Testimonial from "../components/Testimonial.vue";
export default {
  components: { HomeSlider, ProjectsList, Testimonial },
  name: "HomePage",
};
</script>